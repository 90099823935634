import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProfilePic from "../static/images/profile.jpg";

const Home = () => {
    return (
        <motion.div className="home-con"
            animate={{
                transform: ["translate(-50%, 10rem)", "translate(-50%, 0)"],
                opacity: [0, 1]
            }}
            transition={{
                ease: "easeOut"
            }} >
            <div className="home-img-con">
                <div className="bg-img"></div>
                <img src={ProfilePic} alt="" className="profile-photo" />
            </div>
            <div className="name-con">
                <h1 className="name">Darshan Darji</h1>
                <h3 className="tagline">
                    <motion.div animate={{
                        // borderBottomRightRadius: ["0%", "50%", "0%"],
                        // borderTopLeftRadius: ["0%", "50%", "0%"],
                        // scale: [1, 1.3, 1],
                        // rotateX: ["0deg", "90deg", "0deg"],
                        // filter: ["blur(30px)", "blur(0px)", "blur(30px)"]
                        // rotateZ: ["0deg", "90deg", "0deg"]
                    }}
                        transition={{
                            ease: "easeInOut",
                            duration: 3,
                            repeat: Infinity,
                            repeatDelay: 2,
                    }}
                    style={{ display:"inline-block" }}>Developer</motion.div>
                </h3>
            </div>
            <div className="social-con">
                <Link to="https://www.linkedin.com/in/imdarshannn" target="_blank"><i className="fa-brands fa-linkedin"></i></Link>
                <Link to="https://x.com/im_darshhh" target="_blank"><i className="fa-brands fa-x-twitter"></i></Link>
                <Link to="https://github.com/Darshan-SD" target="_blank"><i className="fa-brands fa-github"></i></Link>
            </div>
            <a className="hire-con" href="mailto:darshandarji.code@gmail.com">
                <i class="fa-solid fa-envelope"></i>Hire me
            </a>
        </motion.div>
    )
}

export default Home;