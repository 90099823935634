import { motion } from "framer-motion";

const BackgroundDesign = () => {
    return (
        <div className="background-con">
            {[...Array(10).keys()].map(() => {
                return <motion.div className="bg-box bg-box1">
                    {[...Array(10).keys()].map(() => {
                        return <motion.div 
                        animate={{
                            // borderBottomRightRadius: ["0%", "50%", "0%"],
                            // borderTopLeftRadius: ["0%", "50%", "0%"],
                            scale: [1.5, 2.5, 1.5],
                            // rotateX: ["0deg", "45deg", "0deg"],
                            // rotateZ: ["0deg", "90deg", "0deg"]
                        }}
                        transition={{
                            ease: "linear",
                            duration: 120,
                            repeat: Infinity,
                            repeatDelay: 2,
                        }}
                        className="bg-box bg-box2"></motion.div>
                    })}
                </motion.div>
            })}
        </div>
    )
}

export default BackgroundDesign;