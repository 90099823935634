import { motion } from "framer-motion";
import CodesipImg from "../static/images/codesip.jpeg";
import BFIImage from "../static/images/builtforimpact.png";
import DraftileImage from "../static/images/draftile.png";
import GMTVImage from "../static/images/GMTV.png";
import WeatherImage from "../static/images/weather.png";
import { Link } from "react-router-dom";

const Projects = ({ flipTransition }) => {

    var projectData = [
        {
            "image": CodesipImg,
            "name": "Codesip",
            "desc": "Your frontend development companion",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": "https://codesip.tech",
                "doc": null,
                "github": null
            }
        },
        {
            "image": BFIImage,
            "name": "Built For Impact",
            "desc": "Helped the company to improve the website by building various pages",
            "technology": ["Wordpress"],
            "links": {
                "link": "https://builtforimpact.ca",
                "doc": null,
                "github": null
            }
        },
        {
            "image": DraftileImage,
            "name": "Draftile",
            "desc": "Wallpaper website",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs", "Bootstrap"],
            "links": {
                "link": "https://dwallpapers.netlify.app",
                "doc": null,
                "github": null
            }
        },
        {
            "image": GMTVImage,
            "name": "GMTV",
            "desc": "Movies & TV Series Tracking. This project was developed as a part of undergraduate studies",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": null
            }
        },
        {
            "image": WeatherImage,
            "name": "Weatherly",
            "desc": "Weather web app. Developed to learn ReactJS and NodeJS.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": null
            }
        },
    ];
    return (
        <motion.div
            variants={flipTransition}
            initial="hidden"
            animate="show"
        >

            <h2 className="heading">Work</h2>
            <div className="projects">
                {
                    projectData.map((project) => {
                        return (
                            <div className="project-con">
                                <div className="project-img-con" style={{ backgroundImage: `url(${project.image})` }}></div>
                                <h3 className="project-name">{project.name}</h3>
                                <h4 className="project-desc">{project.desc}</h4>
                                <div className="project-links">
                                    {project.links.link ?
                                        <Link to={project.links.link} target="_blank">
                                            <i className="fa-solid fa-link"></i>
                                        </Link>
                                        : ""}
                                    {project.links.doc ?
                                        <Link to={project.links.doc} target="_blank">
                                            <i className="fa-solid fa-file"></i>
                                        </Link>
                                        : ""}
                                    {project.links.github ?
                                        <Link to={project.links.github} target="_blank">
                                            <i className="fa-brands fa-github"></i>
                                        </Link>
                                        : ""}
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <h2 className="heading">Contribution</h2>
            <div className="github-con">
                <a href="https://github-readme-stats.vercel.app/api?username=Darshan-SD&theme=holi" className="github-link">
                    <img
                        src="https://github-readme-stats.vercel.app/api?username=Darshan-SD&theme=holi&show_icons=true&rank_icon=github&custom_title=Github Stats"
                        alt="@Darshan's Holopin board"
                    />
                </a>
            </div>

            <h2 className="heading">Holopin Badges</h2>
            <div className="holopin-con">
                <a href="https://holopin.io/@darshansd" className="holopin-link">
                    <img
                        src="https://holopin.me/darshansd"
                        alt="@Darshan's Holopin board"
                    />
                </a>
            </div>
        </motion.div>
    )
}

export default Projects;