import { motion } from "framer-motion";

const Loader = () => {
    return (
        <>
            {/* <motion.svg>
                <motion.path />
            </motion.svg> */}

            <motion.div
                className="loader-con"
                animate={{
                    transform: ["translateY(0)", "translateY(-5%) scale(1.1)", "translateY(0)", "translateY(0)"]
                }}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                    repeatDelay: 0
                }}
            >

                {/* Code SVG */}
                <motion.svg
                    // fill="#ffffff"
                    style={{ width: "80%", height: "80%" }}
                    viewBox="0 -2 20 20"
                    xmlns="http://www.w3.org/2000/svg"

                    animate={{ display: ["none", "block", "block", "none"] }}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        repeatDelay: 2
                    }}
                >
                    <motion.path
                        // initial={{ pathLength: 0 }}
                        animate={{ pathLength: [0, 1, 0], display: ["none", "block", "block", "none"] }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "loop",
                            repeatDelay: 2
                        }}
                        strokeWidth={0.3}
                        strokeDasharray="2 2"
                        fill="none"
                        d="M12.7361611,0.063952038 C13.2551391,0.263360331 13.5227261,0.869148905 13.3338336,1.41701869 L8.54555162,15.3051026 C8.35665911,15.8529724 7.78281676,16.1354563 7.26383885,15.936048 C6.74486095,15.7366397 6.47727387,15.1308511 6.66616638,14.5829813 L11.4544484,0.694897379 C11.6433409,0.147027596 12.2171832,-0.135456255 12.7361611,0.063952038 Z M2.41421356,8.25614867 L5.94974747,11.9885083 C6.34027176,12.4007734 6.34027176,13.0691871 5.94974747,13.4814522 C5.55922318,13.8937173 4.9260582,13.8937173 4.53553391,13.4814522 L0.292893219,9.0026206 C-0.0976310729,8.59035554 -0.0976310729,7.9219418 0.292893219,7.50967674 L4.53553391,3.03084515 C4.9260582,2.61858008 5.55922318,2.61858008 5.94974747,3.03084515 C6.34027176,3.44311021 6.34027176,4.11152395 5.94974747,4.52378901 L2.41421356,8.25614867 Z M17.5857864,8.25614867 L14.0502525,4.52378901 C13.6597282,4.11152395 13.6597282,3.44311021 14.0502525,3.03084515 C14.4407768,2.61858008 15.0739418,2.61858008 15.4644661,3.03084515 L19.7071068,7.50967674 C20.0976311,7.9219418 20.0976311,8.59035554 19.7071068,9.0026206 L15.4644661,13.4814522 C15.0739418,13.8937173 14.4407768,13.8937173 14.0502525,13.4814522 C13.6597282,13.0691871 13.6597282,12.4007734 14.0502525,11.9885083 L17.5857864,8.25614867 Z" />
                </motion.svg>

                {/* Camera */}
                <motion.svg
                    style={{ width: "80%", height: "80%" }}
                    viewBox="0 0 52 52"
                    xmlns="http://www.w3.org/2000/svg"

                    animate={{ display: ["none", "block", "block", "none"] }}
                    transition={{
                        duration: 1,
                        delay: 1,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        repeatDelay: 2
                    }}>
                    {/* <g> */}
                    <motion.path
                        animate={{ pathLength: [0, 1, 0] }}
                        transition={{
                            duration: 1,
                            delay: 1,
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "loop",
                            repeatDelay: 2
                        }}
                        strokeWidth={0.2}
                        strokeDasharray="2 2"
                        fill="none"
                        d="M26,20c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S30.4,20,26,20z" />
                    <motion.path
                        animate={{ pathLength: [0, 1, 0] }}
                        transition={{
                            duration: 1,
                            delay: 1,
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "loop",
                            repeatDelay: 2
                        }}
                        strokeWidth={0.8}
                        strokeDasharray="2 2"
                        fill="none"
                        d="M46,14h-5.2c-1.4,0-2.6-0.7-3.4-1.8l-2.3-3.5C34.4,7,32.7,6,30.9,6h-9.8c-1.8,0-3.5,1-4.3,2.7l-2.3,3.5
		c-0.7,1.1-2,1.8-3.4,1.8H6c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h40c2.2,0,4-1.8,4-4V18C50,15.8,48.2,14,46,14z M26,40
		c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S32.6,40,26,40z"/>
                    {/* </g> */}
                </motion.svg>

                {/* Guitar */}
                <motion.svg
                    style={{ width: "80%", height: "80%" }}
                    viewBox="0 0 29.711 29.711"
                    xmlns="http://www.w3.org/2000/svg"

                    animate={{ display: ["none", "block", "block", "none"] }}
                    transition={{
                        duration: 1,
                        delay: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "loop",
                        repeatDelay: 2
                    }}>
                    <g id="guitar">
                        <motion.path
                            animate={{ pathLength: [0, 1, 0] }}
                            transition={{
                                duration: 1,
                                delay: 2,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatType: "loop",
                                repeatDelay: 2
                            }}
                            strokeWidth={0.4}
                            strokeDasharray="2 2"
                            fill="none"
                            d="M28.71,4.273l0.668-0.668c0.443-0.443,0.443-1.164,0-1.607l-1.664-1.664C27.501,0.121,27.212,0,26.91,0
		s-0.591,0.12-0.804,0.333l-0.669,0.668l-0.653-0.653c-0.271-0.271-0.711-0.271-0.982,0c-0.271,0.271-0.271,0.71,0,0.981
		l0.654,0.653L24.129,2.31l-0.653-0.654c-0.271-0.271-0.711-0.271-0.981,0c-0.271,0.271-0.271,0.711,0,0.982l0.653,0.653
		L22.82,3.619l-0.653-0.653c-0.271-0.271-0.71-0.271-0.981,0c-0.271,0.271-0.271,0.71,0,0.981L21.839,4.6l-4.939,4.94
		c-2.369-1.258-5.109-1.105-6.812,0.597c-0.315,0.315-0.577,0.667-0.788,1.046c-0.458,0.828-1.325,1.35-2.273,1.367
		c-1.878,0.035-3.653,0.703-4.986,2.035c-3.068,3.068-2.625,8.484,0.988,12.097s9.03,4.056,12.098,0.988
		c1.332-1.332,2.001-3.107,2.035-4.986c0.018-0.946,0.541-1.816,1.367-2.273c0.379-0.21,0.73-0.472,1.047-0.787
		c1.701-1.702,1.854-4.444,0.596-6.812l4.94-4.939l0.653,0.654c0.137,0.135,0.313,0.203,0.491,0.203s0.354-0.068,0.491-0.203
		c0.271-0.271,0.271-0.711,0-0.982l-0.654-0.653l0.326-0.327l0.654,0.654c0.137,0.135,0.314,0.203,0.491,0.203
		c0.178,0,0.354-0.068,0.491-0.203c0.271-0.271,0.271-0.711,0-0.982l-0.654-0.653l0.327-0.328l0.655,0.655
		c0.135,0.135,0.313,0.203,0.49,0.203s0.354-0.068,0.49-0.203c0.271-0.271,0.271-0.711,0-0.982L28.71,4.273z M15.787,18.504
		c-1.265,1.265-3.315,1.265-4.58,0s-1.265-3.315,0-4.58s3.316-1.265,4.58,0C17.052,15.189,17.053,17.239,15.787,18.504z"/>
                    </g>
                    <g id="Layer_1">
                    </g>
                </motion.svg>
            </motion.div>
        </>
    )
}

export default Loader;